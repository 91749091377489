define("@ember-data/store/-debug/index", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertPolymorphicType = void 0;
  /*
    Assert that `addedRecord` has a valid type so it can be added to the
    relationship of the `record`.
  
    The assert basically checks if the `addedRecord` can be added to the
    relationship (specified via `relationshipMeta`) of the `record`.
  
    This utility should only be used internally, as both record parameters must
    be an InternalModel and the `relationshipMeta` needs to be the meta
    information about the relationship, retrieved via
    `record.relationshipFor(key)`.
  */
  var assertPolymorphicType;
  if (true /* DEBUG */) {
    var checkPolymorphic = function checkPolymorphic(modelClass, addedModelClass) {
      if (modelClass.__isMixin) {
        return modelClass.__mixin.detect(addedModelClass.PrototypeMixin) ||
        // handle native class extension e.g. `class Post extends Model.extend(Commentable) {}`
        modelClass.__mixin.detect(Object.getPrototypeOf(addedModelClass).PrototypeMixin);
      }
      return addedModelClass.prototype instanceof modelClass || modelClass.detect(addedModelClass);
    };
    _exports.assertPolymorphicType = assertPolymorphicType = function assertPolymorphicType(parentIdentifier, parentDefinition, addedIdentifier, store) {
      store = store._store ? store._store : store; // allow usage with storeWrapper
      var addedModelName = addedIdentifier.type;
      var parentModelName = parentIdentifier.type;
      var key = parentDefinition.key;
      var relationshipModelName = parentDefinition.type;
      var relationshipClass = store.modelFor(relationshipModelName);
      var addedClass = store.modelFor(addedModelName);
      var assertionMessage = "The '" + addedModelName + "' type does not implement '" + relationshipModelName + "' and thus cannot be assigned to the '" + key + "' relationship in '" + parentModelName + "'. Make it a descendant of '" + relationshipModelName + "' or use a mixin of the same name.";
      var isPolymorphic = checkPolymorphic(relationshipClass, addedClass);
      (true && !(isPolymorphic) && (0, _debug.assert)(assertionMessage, isPolymorphic));
    };
  }
});