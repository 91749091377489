define("ember-cli-flash/flash/object", ["exports", "@ember/object/evented", "@ember/object", "@ember/runloop", "ember-cli-flash/utils/computed"], function (_exports, _evented, _object, _runloop, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _object.default.extend(_evented.default, {
    exitTimer: null,
    exiting: false,
    isExitable: true,
    initializedTime: null,
    _guid: (0, _computed.guidFor)('message').readOnly(),
    init: function init() {
      this._super.apply(this, arguments);
      if ((0, _object.get)(this, 'sticky')) {
        return;
      }
      this.timerTask();
      this._setInitializedTime();
    },
    destroyMessage: function destroyMessage() {
      this._cancelTimer();
      var exitTaskInstance = (0, _object.get)(this, 'exitTaskInstance');
      if (exitTaskInstance) {
        (0, _runloop.cancel)(exitTaskInstance);
        this._teardown();
      } else {
        this.exitTimerTask();
      }
    },
    exitMessage: function exitMessage() {
      if (!(0, _object.get)(this, 'isExitable')) {
        return;
      }
      this.exitTimerTask();
      this.trigger('didExitMessage');
    },
    willDestroy: function willDestroy() {
      var onDestroy = (0, _object.get)(this, 'onDestroy');
      if (onDestroy) {
        onDestroy();
      }
      this._cancelTimer();
      this._cancelTimer('exitTaskInstance');
      this._super.apply(this, arguments);
    },
    preventExit: function preventExit() {
      (0, _object.set)(this, 'isExitable', false);
    },
    allowExit: function allowExit() {
      (0, _object.set)(this, 'isExitable', true);
      this._checkIfShouldExit();
    },
    timerTask: function timerTask() {
      var _this = this;
      var timeout = (0, _object.get)(this, 'timeout');
      if (!timeout) {
        return;
      }
      var timerTaskInstance = (0, _runloop.later)(function () {
        _this.exitMessage();
      }, timeout);
      (0, _object.set)(this, 'timerTaskInstance', timerTaskInstance);
    },
    exitTimerTask: function exitTimerTask() {
      var _this2 = this;
      if ((0, _object.get)(this, 'isDestroyed')) {
        return;
      }
      (0, _object.set)(this, 'exiting', true);
      var extendedTimeout = (0, _object.get)(this, 'extendedTimeout');
      if (extendedTimeout) {
        var exitTaskInstance = (0, _runloop.later)(function () {
          _this2._teardown();
        }, extendedTimeout);
        (0, _object.set)(this, 'exitTaskInstance', exitTaskInstance);
      } else {
        this._teardown();
      }
    },
    // private
    _setInitializedTime: function _setInitializedTime() {
      var currentTime = new Date().getTime();
      (0, _object.set)(this, 'initializedTime', currentTime);
    },
    _getElapsedTime: function _getElapsedTime() {
      var currentTime = new Date().getTime();
      var initializedTime = (0, _object.get)(this, 'initializedTime');
      return currentTime - initializedTime;
    },
    _cancelTimer: function _cancelTimer() {
      var taskName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'timerTaskInstance';
      var taskInstance = (0, _object.get)(this, taskName);
      if (taskInstance) {
        (0, _runloop.cancel)(taskInstance);
      }
    },
    _checkIfShouldExit: function _checkIfShouldExit() {
      if (this._getElapsedTime() >= (0, _object.get)(this, 'timeout') && !(0, _object.get)(this, 'sticky')) {
        this._cancelTimer();
        this.exitMessage();
      }
    },
    _teardown: function _teardown() {
      var queue = (0, _object.get)(this, 'flashService.queue');
      if (queue) {
        queue.removeObject(this);
      }
      this.destroy();
      this.trigger('didDestroyMessage');
    }
  });
});