define("ember-colpick/components/col-pick", ["exports", "@ember/component", "ember-colpick/mixins/col-pick"], function (_exports, _component, _colPick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-mixins */
  /* eslint-disable ember/no-classic-components */
  var _default = _exports.default = _component.default.extend(_colPick.default);
});