define("ember-multiselect-checkboxes/templates/components/multiselect-checkboxes", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "5CWHkIai",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"checkboxes\"]]],null]],null],null,[[[41,[48,[30,3]],[[[1,\"    \"],[18,3,[[30,1,[\"option\"]],[30,1,[\"isSelected\"]],[30,2]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"\\n        \"],[8,[39,5],[[16,\"disabled\",[30,0,[\"disabled\"]]]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,1,[\"isSelected\"]]]],null],[1,\"\\n        \"],[1,[30,1,[\"label\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[1,2]],null]],[\"checkbox\",\"index\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"has-block\",\"yield\",\"input\"]]",
    "moduleName": "ember-multiselect-checkboxes/templates/components/multiselect-checkboxes.hbs",
    "isStrictMode": false
  });
});