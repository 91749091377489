define("ember-page-title/helpers/page-title", ["exports", "@ember/runloop", "@ember/service", "@ember/component/helper", "@ember/object", "@ember/object/internals", "@ember/polyfills", "@ember/application"], function (_exports, _runloop, _service, _helper, _object, _internals, _polyfills, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function updateTitle(tokens) {
    (0, _object.set)(this, 'title', tokens.toString());
  }

  /**
    `{{page-title}}` is used to communicate with
  
    @public
    @method page-title
   */
  var _default = _exports.default = _helper.default.extend({
    pageTitleList: (0, _service.inject)(),
    headData: (0, _service.inject)(),
    init: function init() {
      this._super();
      var tokens = (0, _object.get)(this, 'pageTitleList');
      tokens.push({
        id: (0, _internals.guidFor)(this)
      });
    },
    compute: function compute(params, _hash) {
      // page-title used via title ast transform, which is deprecated
      if (_hash && _hash._deprecate) {
        // eslint-disable-next-line no-console
        console.warn('Using `{{title}}` helper is deprecated, use `{{page-title}}` instead. ' + _hash._deprecate);
      }
      var tokens = (0, _object.get)(this, 'pageTitleList');
      var hash = (0, _polyfills.assign)({}, _hash);
      hash.id = (0, _internals.guidFor)(this);
      hash.title = params.join('');
      tokens.push(hash);
      (0, _runloop.scheduleOnce)('afterRender', (0, _object.get)(this, 'headData'), updateTitle, tokens);
      return '';
    },
    destroy: function destroy() {
      var tokens = (0, _object.get)(this, 'pageTitleList');
      var id = (0, _internals.guidFor)(this);
      tokens.remove(id);
      var router = (0, _application.getOwner)(this).lookup('router:main');
      var routes = router._routerMicrolib || router.router;
      var _ref = routes || {},
        activeTransition = _ref.activeTransition;
      var headData = (0, _object.get)(this, 'headData');
      if (activeTransition) {
        activeTransition.promise.finally(function () {
          if (headData.isDestroyed) {
            return;
          }
          (0, _runloop.scheduleOnce)('afterRender', headData, updateTitle, tokens);
        });
      } else {
        (0, _runloop.scheduleOnce)('afterRender', headData, updateTitle, tokens);
      }
    }
  });
});