define("ember-cli-flash/utils/computed", ["exports", "@ember/utils", "@ember/object", "@ember/object/internals", "@ember/array"], function (_exports, _utils, _object, _internals, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.guidFor = guidFor;
  function add() {
    for (var _len = arguments.length, dependentKeys = new Array(_len), _key = 0; _key < _len; _key++) {
      dependentKeys[_key] = arguments[_key];
    }
    var computedFunc = (0, _object.computed)({
      get: function get() {
        var _this = this;
        var values = dependentKeys.map(function (dependentKey) {
          var value = (0, _object.get)(_this, dependentKey);
          if ((0, _utils.typeOf)(value) !== 'number') {
            return;
          }
          return value;
        });
        return (0, _array.A)(values).compact().reduce(function (prev, curr) {
          return prev + curr;
        });
      }
    });
    return computedFunc.property.apply(computedFunc, dependentKeys);
  }
  function guidFor(dependentKey) {
    return (0, _object.computed)(dependentKey, {
      get: function get() {
        var value = (0, _object.get)(this, dependentKey);

        // it's possible that a value has no toString as some objects don't implement the guid field
        // this early return it to avoid errors being thrown when calling undefined.toString()
        if ((0, _utils.isNone)(value)) return;
        return (0, _internals.guidFor)(value.toString());
      }
    });
  }
});