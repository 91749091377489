define("ember-multiselect-checkboxes/components/multiselect-checkboxes", ["exports", "@ember/object", "@ember/component", "@ember/array", "@ember/application", "ember-multiselect-checkboxes/templates/components/multiselect-checkboxes"], function (_exports, _object, _component, _array, _application, _multiselectCheckboxes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Checkbox = _object.default.extend({
    isSelected: (0, _object.computed)('value', 'selection.[]', {
      get: function get() {
        return this.get('selection').includes(this.get('value'));
      },
      set: function set(_, checked) {
        var selection = this.get('selection');
        var selected = selection.includes(this.get('value'));
        var onchange = this.get('onchange');
        var updateSelectionValue = this.get('updateSelectionValue');
        var isMutable = typeof selection.addObject === 'function' && typeof selection.removeObject === 'function';

        // Dispatch onchange event to handler with updated selection if handler is specified
        if (onchange) {
          var updated = (0, _array.A)(selection.slice());
          var operation;
          if (checked && !selected) {
            operation = 'added';
            updated.addObject(this.get('value'));
          } else if (!checked && selected) {
            operation = 'removed';
            updated.removeObject(this.get('value'));
          }
          onchange(updated, this.get('value'), operation);
        }

        // Mutate selection if updateSelectionValue is true and selection is mutable
        if (updateSelectionValue !== false && isMutable) {
          if (checked && !selected) {
            selection.addObject(this.get('value'));
          } else if (!checked && selected) {
            selection.removeObject(this.get('value'));
          }
          return checked;
        } else {
          // Only change the checked status of the checkbox when selection is mutated, because if
          // it is not mutated and the onchange handler does not update the bound selection value the
          // displayed checkboxes would be out of sync with bound selection value.
          return !checked;
        }
      }
    })
  });
  var _default = _exports.default = _component.default.extend({
    layout: _multiselectCheckboxes.default,
    classNames: ['multiselect-checkboxes'],
    tagName: 'ul',
    i18n: (0, _object.computed)(function () {
      return (0, _application.getOwner)(this).lookup('service:i18n');
    }),
    checkboxes: (0, _object.computed)('options.[]', 'labelProperty', 'valueProperty', 'selection', 'translate', 'i18n.locale', function () {
      var _this = this;
      var labelProperty = this.get('labelProperty');
      var valueProperty = this.get('valueProperty');
      var selection = (0, _array.A)(this.get('selection'));
      var onchange = this.get('onchange');
      var updateSelectionValue = this.get('updateSelectionValue') !== undefined ? this.get('updateSelectionValue') : true;
      var options = (0, _array.A)(this.get('options'));
      var translate = this.get('translate');
      var checkboxes = options.map(function (option) {
        var label, value;
        if (labelProperty) {
          if (typeof option.get === 'function') {
            label = option.get(labelProperty);
          } else {
            label = option[labelProperty];
          }
        } else {
          label = String(option);
        }
        if (translate && label && _this.get('i18n')) {
          label = _this.get('i18n').t(label);
        }
        if (valueProperty) {
          if (typeof option.get === 'function') {
            value = option.get(valueProperty);
          } else {
            value = option[valueProperty];
          }
        } else {
          value = option;
        }
        return Checkbox.create({
          option: option,
          label: label,
          value: value,
          selection: selection,
          onchange: onchange,
          updateSelectionValue: updateSelectionValue
        });
      });
      return (0, _array.A)(checkboxes);
    })
  });
});