define("ember-cli-flash/services/flash-messages", ["exports", "@ember/service", "@ember/utils", "@ember/debug", "@ember/object", "@ember/object/computed", "@ember/string", "@ember/polyfills", "@ember/array", "ember-cli-flash/flash/object", "ember-cli-flash/utils/object-without", "@ember/application", "ember-cli-flash/utils/flash-message-options", "ember-cli-flash/utils/get-with-default"], function (_exports, _service, _utils, _debug, _object, _computed, _string, _polyfills, _array, _object2, _objectWithout, _application, _flashMessageOptions, _getWithDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    isEmpty: (0, _computed.equal)('queue.length', 0).readOnly(),
    _guids: (0, _computed.mapBy)('queue', '_guid').readOnly(),
    arrangedQueue: (0, _computed.sort)('queue', function (a, b) {
      if (a.priority < b.priority) {
        return 1;
      } else if (a.priority > b.priority) {
        return -1;
      }
      return 0;
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);
      this._setDefaults();
      this.queue = (0, _array.A)();
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.clearMessages();
    },
    add: function add() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this._enqueue(this._newFlashMessage(options));
      return this;
    },
    clearMessages: function clearMessages() {
      var flashes = (0, _object.get)(this, 'queue');
      if ((0, _utils.isNone)(flashes)) {
        return;
      }
      flashes.forEach(function (flash) {
        return flash.destroyMessage();
      });
      flashes.clear();
      return this;
    },
    registerTypes: function registerTypes() {
      var _this = this;
      var types = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : (0, _array.A)();
      types.forEach(function (type) {
        return _this._registerType(type);
      });
      return this;
    },
    peekFirst: function peekFirst() {
      return (0, _object.get)(this, 'queue.firstObject');
    },
    peekLast: function peekLast() {
      return (0, _object.get)(this, 'queue.lastObject');
    },
    getFlashObject: function getFlashObject() {
      var errorText = 'A flash message must be added before it can be returned';
      (true && !((0, _object.get)(this, 'queue').length) && (0, _debug.assert)(errorText, (0, _object.get)(this, 'queue').length));
      return this.peekLast();
    },
    _newFlashMessage: function _newFlashMessage() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      (true && !((0, _object.get)(this, 'defaultPreventDuplicates') ? options.message : true) && (0, _debug.assert)('The flash message cannot be empty when preventDuplicates is enabled.', (0, _object.get)(this, 'defaultPreventDuplicates') ? options.message : true));
      (true && !((0, _object.get)(options, 'preventDuplicates') ? options.message : true) && (0, _debug.assert)('The flash message cannot be empty when preventDuplicates is enabled.', (0, _object.get)(options, 'preventDuplicates') ? options.message : true));
      var flashService = this;
      var allDefaults = (0, _getWithDefault.default)(this, 'flashMessageDefaults', {});
      var defaults = (0, _objectWithout.default)(allDefaults, ['types', 'injectionFactories', 'preventDuplicates']);
      var flashMessageOptions = (0, _polyfills.assign)({}, defaults, {
        flashService: flashService
      });
      for (var key in options) {
        var value = (0, _object.get)(options, key);
        var option = this._getOptionOrDefault(key, value);
        (0, _object.set)(flashMessageOptions, key, option);
      }
      return _object2.default.create(flashMessageOptions);
    },
    _getOptionOrDefault: function _getOptionOrDefault(key, value) {
      var defaults = (0, _getWithDefault.default)(this, 'flashMessageDefaults', {});
      var defaultOption = (0, _object.get)(defaults, key);
      if ((0, _utils.typeOf)(value) === 'undefined') {
        return defaultOption;
      }
      return value;
    },
    flashMessageDefaults: (0, _object.computed)(function () {
      var config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      var overrides = (0, _getWithDefault.default)(config, 'flashMessageDefaults', {});
      return (0, _flashMessageOptions.default)(overrides);
    }),
    _setDefaults: function _setDefaults() {
      var defaults = (0, _getWithDefault.default)(this, 'flashMessageDefaults', {});
      for (var key in defaults) {
        var classifiedKey = (0, _string.classify)(key);
        var defaultKey = "default".concat(classifiedKey);
        (0, _object.set)(this, defaultKey, defaults[key]);
      }
      this.registerTypes((0, _getWithDefault.default)(this, 'defaultTypes', (0, _array.A)()));
    },
    _registerType: function _registerType(type) {
      var _this2 = this;
      (true && !(type) && (0, _debug.assert)('The flash type cannot be undefined', type));
      this[type] = function (message) {
        var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        var flashMessageOptions = (0, _polyfills.assign)({}, options);
        (0, _object.setProperties)(flashMessageOptions, {
          message: message,
          type: type
        });
        return _this2.add(flashMessageOptions);
      };
    },
    _hasDuplicate: function _hasDuplicate(guid) {
      return (0, _object.get)(this, '_guids').includes(guid);
    },
    _enqueue: function _enqueue(flashInstance) {
      var instancePreventDuplicates = (0, _object.get)(flashInstance, 'preventDuplicates');
      var preventDuplicates = typeof instancePreventDuplicates === 'boolean' ?
      // always prefer instance option over global option
      instancePreventDuplicates : (0, _object.get)(this, 'defaultPreventDuplicates');
      if (preventDuplicates) {
        var guid = (0, _object.get)(flashInstance, '_guid');
        if (this._hasDuplicate(guid)) {
          (true && (0, _debug.warn)('Attempting to add a duplicate message to the Flash Messages Service', false, {
            id: 'ember-cli-flash.duplicate-message'
          }));
          return;
        }
      }
      return (0, _object.get)(this, 'queue').pushObject(flashInstance);
    }
  });
});