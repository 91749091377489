(function () {
  function vendorModule() {
    'use strict';

    var iconpicker = self['$'].iconpicker;
    // delete self['$'].iconpicker;

    return { default: iconpicker };
  }

  define('fontAwesomeIconPicker', [], vendorModule);
})();
