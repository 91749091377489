define("ember-colpick/components/col-pick-input", ["exports", "@ember/legacy-built-in-components", "ember-colpick/mixins/col-pick"], function (_exports, _legacyBuiltInComponents, _colPick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-classic-classes */
  /* eslint-disable ember/no-mixins */
  var _default = _exports.default = _legacyBuiltInComponents.TextField.extend(_colPick.default, {
    flat: false
  });
});