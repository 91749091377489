(function () {
  function vendorModule() {
    'use strict';

    var o = self['o'];
    return { default: o };
  }

  define('o', [], vendorModule);
})();
