define("ember-qrcode-shim/components/qr-code", ["exports", "@ember/component", "@ember/object", "qrcode"], function (_exports, _component, _object, _qrcode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    text: null,
    colorLight: '#ffffff',
    colorDark: '#000000',
    width: 128,
    height: 128,
    // L/M/Q/H
    correctLevel: 'Q',
    didInsertElement: function didInsertElement() {
      var text = this.get('text');
      var elementId = this.get('elementId');
      var correctLevel = this.get('correctLevel');
      var colorLight = this.get('colorLight');
      var colorDark = this.get('colorDark');
      var width = this.get('width');
      var height = this.get('height');
      var qrcode = new _qrcode.default(elementId, {
        text: text,
        width: width,
        height: height,
        colorDark: colorDark,
        colorLight: colorLight,
        correctLevel: _qrcode.default.CorrectLevel[correctLevel]
      });
      this.set('qrcode', qrcode);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('qrcode').clear();
    },
    _recreateCode: (0, _object.observer)('text', function () {
      this.get('qrcode').makeCode(this.get('text'));
    })
  });
});