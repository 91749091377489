define("ember-cli-showdown/components/markdown-to-html", ["exports", "showdown", "@ember/component", "@ember/string", "@ember/object", "@ember/application", "ember-cli-showdown/templates/components/markdown-to-html"], function (_exports, _showdown, _component, _string, _object, _application, _markdownToHtml) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CONFIG_LOOKUP = 'config:environment';
  var ShowdownComponent = _component.default.extend({
    layout: _markdownToHtml.default,
    markdown: '',
    extensions: null,
    _globalOptions: null,
    defaultOptionKeys: (0, _object.computed)(function () {
      return Object.keys(_showdown.default.getDefaultOptions());
    }).readOnly(),
    init: function init() {
      this._super.apply(this, arguments);
      var owner = (0, _application.getOwner)(this);
      if (!this.extensions) {
        this.extensions = [];
      }
      if (owner && owner.hasRegistration(CONFIG_LOOKUP)) {
        this._globalOptions = (owner.resolveRegistration(CONFIG_LOOKUP) || {}).showdown;
      }
    },
    html: (0, _object.computed)('markdown', 'converter', function () {
      var showdownOptions = this.getShowdownProperties((0, _object.get)(this, 'defaultOptionKeys'));
      var converter = (0, _object.get)(this, 'converter');
      for (var option in showdownOptions) {
        if (showdownOptions.hasOwnProperty(option) && typeof showdownOptions[option] !== 'undefined') {
          converter.setOption(option, showdownOptions[option]);
        }
      }
      return (0, _string.htmlSafe)(converter.makeHtml((0, _object.get)(this, 'markdown')));
    }).readOnly(),
    converter: (0, _object.computed)('extensions', function () {
      var extensions = (0, _object.get)(this, 'extensions');
      if (typeof extensions === 'string') {
        extensions = extensions.split(' ');
      }
      return new _showdown.default.Converter({
        extensions: extensions
      });
    }),
    getShowdownProperties: function getShowdownProperties(keyNames) {
      var _this = this;
      return keyNames.reduce(function (accumulator, keyName) {
        var value = (0, _object.get)(_this, keyName);
        if (value === undefined && _this._globalOptions) {
          value = (0, _object.get)(_this._globalOptions, keyName);
        }
        accumulator[keyName] = value;
        return accumulator;
      }, {});
    }
  });
  ShowdownComponent.reopenClass({
    positionalParams: ['markdown']
  });
  var _default = _exports.default = ShowdownComponent;
});