define('ember-cli-markdown-it/helpers/markdown-html', ['exports', 'markdown-it'], function (exports, _markdownIt) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.markdownHtml = markdownHtml;
  function markdownHtml(params, hash) {
    if (Ember.isEmpty(params)) {
      return '';
    }

    var options = hash.options || {};

    var md = (0, _markdownIt.default)(options);

    if ('plugins' in hash) {
      hash.plugins.forEach(function (plugin) {
        md = md.use(plugin);
      });
    }
    return Ember.String.htmlSafe(md.render(params[0]));
  }

  exports.default = Ember.Helper.helper(markdownHtml);
});