window.EmberENV = (function(EmberENV, extra) {
  for (var key in extra) {
    EmberENV[key] = extra[key];
  }

  return EmberENV;
})(window.EmberENV || {}, {"LOG_STACKTRACE_ON_DEPRECATION":false,"FEATURES":{}});

var runningTests = false;


